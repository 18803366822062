import React, { useState } from 'react';
import styled from '@emotion/styled';
import {useLocation, useSearchParams} from "react-router-dom";

const Styled = styled.div`
  button:first-of-type {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  button:last-of-type {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
  button.active-btn {
    background: #323d53;
    color: #fff;
  }
`

const Button = styled.button`
  font-size: 16px;
  width: 150px;
  font-weight: bold;
  padding: 0.5rem;
  background: #fff;
  border: 1px solid #cacaca;
  :hover {
    cursor: pointer;
  }
  :disabled {
    opacity: 0.4;
  } 
`;

const options = [
  { label: 'Panorama', value: 'panorama' },
  { label: 'Detallada', value: 'detallada' },
];

export const Toggle = ({ options }) => {
  const [active, setActive] = useState(options[0])
  let [searchParams, setSearchParams] = useSearchParams();
  let location = useLocation();
  const viewType = searchParams.get('type');
  return (
    <Styled>
      {options.map((option) => {
        const isActive = option.value === viewType;
        return (
          <Button
            onClick={e => {
              var URL = new URLSearchParams(location.search);
              URL.set('type', option.value);
              setActive(option)
              setSearchParams(URL);
              // setSearchParams({
              //   type: option.value
              // });
            }}
            className={isActive ? 'active-btn' : ''}>
            {option.label}
          </Button>
        );
      })}
    </Styled>
  );
}

Toggle.defaultProps = {
  options: options
}
