import React, { Component } from 'react';
import { scaleBand, scaleLinear  } from 'd3-scale';
import { format } from 'd3-format';
import { stack } from 'd3-shape';
import {withSize} from "react-sizeme";
import styled from "@emotion/styled";
import {formatToTwoDecimals} from "../helpers/formatters";
import {max} from "d3-array";

const BoxPlotLabel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  height: 100%;
  text-align: right;
  justify-content: center;
  .label-title {
    font-size: 1rem;
    font-weight: 500;
  }
  .label-description {
    font-size: 10px;
    line-height: 10px;
    font-weight: 300;
  }
`;

const BoxPlotContext = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end; 
  padding: 2rem 0;
  .key-context {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }
  .key-color {
    width: 20px;
    margin-right: 5px;
    border-radius: 5px;
    height: 10px;
  }
  .key-name {
    font-size: 12px;
    font-weight: 300;
  }
`

const keys = [
  { label: 'Rango-Segmento', color: '#ffaca3' },
  { label: 'Promedio', color: '#ff5a47' }
]



export class BoxPlotSpecial extends Component {
  constructor (props) {
    super(props);
    this.state = {
      focusKey: null
    }
  }

  get yScale () {
    const { data, xValueFn } = this.props;
    const height = this.height;
    const values = data.map(xValueFn);
    return scaleBand()
      .domain(values)
      .range([0, height])
      // .paddingInner(0.7)
      .padding(0.8)
  }

  get xScale () {
    const { data } = this.props;
    const p75 = data.map((d) => d.p75);
    const maxP75 = max(p75);
    const width = this.width;
    return scaleLinear()
      .domain([0, maxP75 * 1.1])
      .range([0, width]);
  }

  get width () {
    const { size: { width }, margin } = this.props;
    return  width - margin.left - margin.right;
  }

  get height () {
    const { height, margin } = this.props;
    return  height - margin.top - margin.bottom;
  }

  renderBoxPlotGroups = (element, index) => {
    const { colors, margin } = this.props;
    const { focusKey } = this.state;
    const yScale = this.yScale;
    const xScale = this.xScale;
    const yOffset = yScale.padding(0)(element.label) + (yScale.padding(0).bandwidth() / 2);
    const height = yScale.padding(0.8).bandwidth();
    const width = xScale(element.p75 - element.p25);
    const maxDomain = xScale.domain()[1];
    return (
      <g
        key={element.label}
        data-key={element.label}
        data-values={[element.p25,element.p50,element.p75].join('-')}
        transform={`translate(${0}, ${yOffset})`}>
          <rect
          x={0}
          y={0}
          fill='#ffaca3'
          height={height}
          width={xScale(maxDomain)} />
        <rect
          rx={height / 2}
          x={xScale(element.p25)}
          y={0}
          fill='#ff5a47'
          height={height}
          width={width} />
        <text fill='#3667c1' textAnchor='end' y={13} x={xScale(element.p25)} dx='-0.5rem'>{formatToTwoDecimals(element.p25 * 100)}</text>
        <text fill='#fff' textAnchor='middle' y={13} x={xScale(element.p25) + xScale(element.p75 - element.p25) / 2}>{formatToTwoDecimals(element.p50 * 100)}</text>
        <text fill='#3667c1' textAnchor='start' y={13} x={xScale(element.p75)} dx='0.5rem'>{formatToTwoDecimals(element.p75 * 100)}</text>
      </g>
    );
  }

  renderBoxKeys = (element, index) => {
    return (
      <div
        key={index}
        className='key-context'
      >
        <div className='data-rect key-color' style={{ background: element.color }} />
        <span className='key-name'>{element.label}</span>
      </div>
    );
  }

  handleKeyMouseEnter = (e, d) => {
    const boxKey = e.currentTarget.dataset['boxKey'];
    this.setState({ focusKey: boxKey });
  }

  handleKeyMouseOut = (e) => {
    this.setState({ focusKey: null });
  }

  render () {
    const { size: { width }, data, height, margin, boxKeys } = this.props;
    const yScale = this.yScale;
    const xScale = this.xScale;
    return (
      <div>
        <svg width={width} height={height} style={{ background: '#f9fafa' }}>
          <BandAxis scale={yScale} tx={0} ty={margin.top} margin={margin} />
          <LinearAxis scale={xScale} tx={margin.left} ty={height - margin.bottom} formatValue={d => format('.0%')(d)} />
          <LinearGrid scale={xScale} tx={margin.left} ty={margin.top} size={this.height} />
          <g className='box-plot-data' transform={`translate(${margin.left}, ${margin.top})`}>
            {data.map(this.renderBoxPlotGroups)}
          </g>
        </svg>
        <BoxPlotContext>
          {keys.map(this.renderBoxKeys)}
        </BoxPlotContext>
      </div>
    );
  }
}

BoxPlotSpecial.defaultProps = {
  height: 500,
  boxKeys: [],
  size: {
    width: 0
  },
  data: [],
  margin: {
    top: 20,
    right: 30,
    bottom: 30,
    left: 200
  },
  xValueFn: d => d.label,
  focusKey: null,
  colors: {},
  formatValue: d => (d)
  // yValueFn: d => d
};

const LinearGrid = ({ scale, tx, ty, size, ...props }) => {
  const ticks = scale.ticks();
  return (
    <g className='linear-grid grid' transform={`translate(${tx}, ${ty})`}>
      {
        ticks.map((d, i) => {
          return (
            <g key={i} transform={`translate(${scale(d)}, 0)`}>
              <line style={{ shapeRendering: 'crispedges' }} stroke='#cad3d7' x1={0} y1={0}  x2={0} y2={size} strokeDasharray='2' />
            </g>
          );
        })
      }
    </g>
  );
}

const BandAxis = ({ scale, tx, ty, ...props }) => {
  const ticks = scale.domain();
  const bandwidth = scale.bandwidth();
  return (
    <g className='band-axis axis' transform={`translate(0, ${ty})`}>
      {ticks.map((d, i) => {
        return (
          <g key={i} transform={`translate(0, ${scale.padding(0)(d)})`}>
            <foreignObject x={0} width={props.margin.left} height={scale.padding(0).bandwidth()}>
              <BoxPlotLabel>
                <span className="label-title">OCUPACIÓN {i + 1}</span>
                <span className="label-description">{d}</span>
              </BoxPlotLabel>
            </foreignObject>
          </g>
        )
      })}
    </g>
  )
};

const LinearAxis = ({ scale, tx, ty, formatValue, ...props }) => {
  const ticks = scale.ticks();
  return (
    <g className='linear-axis axis' transform={`translate(${tx}, ${ty})`}>
      {
        ticks.map((d, i) => {
          return (
            <g key={i} transform={`translate(${scale(d)}, 0)`}>
              <text y='9' dy='0.71em' fontSize={12} textAnchor='middle'>{format('.0f')(d * 100)}</text>
            </g>
          );
        })
      }
    </g>
  );
};

export default withSize()(BoxPlotSpecial)