import {useState, useEffect} from "react";
import axiosInstance from "../api/axiosInstance";

export function useChartDictionary() {
  const [chartsDictionary, setChartsDictionary] = useState({});

  useEffect(() => {
    axiosInstance.get('/data_to_filters', { params: { categories: 'plot_info' }})
      .then((res) => {
        const dictionary = res.data[0].plot_info.reduce((acc, val) => {
          acc[val.value] = val;
          return acc;
        }, {})
        setChartsDictionary(dictionary)
      })
      .catch((err) => console.log(err))
  }, [])

  return chartsDictionary;
}

