import React from "react";
import styled from "@emotion/styled";

const Styled = styled.div`
  padding: 1rem;
`

const sections = [
  {
    label: 'Arriba - Derecha',
    description: 'Alta prevalencia y en expansión',
    color: '#3366c7'
  },
  {
    label: 'Arriba - Izquierda',
    description: 'Baja prevalencia y en expansión',
    color: '#75a2a2'
  },
  {
    label: 'Abajo - Derecha',
    description: 'Alta prevalencia y en contracción',
    color: '#ad5e62'
  },
  {
    label: 'Abajo - Izquierda',
    description: 'Baja prevalencia y en contracción',
    color: '#f7b05e'
  },
]

const Section = styled.div`
  display: flex;
  margin: 1rem 0;
  .section-color {
    background: ${props => props.color || '#222'};
    margin-top: 5px;
    border-radius: 5px;
    width: 40px;
    min-width: 40px;
    height: 15px;
    margin-right: 1rem;
  }
  span {
    display: block;
  }
  .section-label {
    font-size: 14px;
  }
  .section-description {
    font-size: 12px;
    color: #686868;
  }
`;

export const ScatterContext = (props) => {
  return (
    <Styled>
      {sections.map((section) => {
        return (
          <Section color={section.color}>
            <div className="section-color" />
            <div className="section-context">
              <span className="section-label">{section.label}</span>
              <span className="section-description">{section.description}</span>
            </div>
          </Section>
        );
      })}
    </Styled>
  );
}