import {groups} from "d3-array";

export const toSankey = (elements) => {
  const nodeMap = new Map();
  elements.nodes.forEach((node) => {
    nodeMap.set(`${node.value}`, `${node.label}`);
  });

  const sankey = {
    nodes: elements.nodes.map(d => {
      return {
        name: nodeMap.get(`${d.value}`)
      }
    }),
    links: elements.edges.map(d => {
      return {
        source: nodeMap.get(`${d.from}`),
        target: nodeMap.get(`${d.to}`),
        value: d.value,
      }
    })
  }
  const activeNodes = sankey.links.reduce((acc, val) => [...acc, val.source, val.target], []);
  sankey.nodes = sankey.nodes.filter(node => activeNodes.includes(node.name));
  return sankey;
}

export const toBoxPlot = (data, keys) => {
  const [sincos, ...indicators] = data;
  const sincosElements = sincos[0].sincos;
  const sincosMap = new Map();
  sincosElements.forEach((element) => {
    sincosMap.set(element.value, element.label.trim());
  });

  const joined = indicators
    .map((indicator, index) => {
      return indicator.map((d) => {
        return {
          education: keys[index],
          label: sincosMap.get(d.sinco2.toString()),
          value: d.value
        };
      });
    })
    .reduce((acc, val) => [...acc, ...val], [])

  return groups(joined, d => d.label)
    .map((profession) => {
      const [label, properties] = profession;
      const obj = {};
      properties.forEach((property) => {
        obj[property.education] = property.value / 100;
      });
      return {
        label,
        ...obj
      };
    });
}