import React from "react";
import Treemap from "../../../components/Treemap";
import { withDataFetch } from "../../../components/withDataFetch";
import {scaleOrdinal} from "d3-scale";
import {arrColors} from "../../../helpers/colors";
import {KeyedTreemap} from "../../KeyedTreemap";
import {format} from "d3-format";

const TreemapWithFetch = withDataFetch(Treemap);

export const EmploymentTreemap = ({ data }) => {
  const colorScale = scaleOrdinal()
    .domain(data.children.map(d => d.name))
    .range(arrColors);
  return (
    <TreemapWithFetch
        height={550}
      queries={[
        {
          url: '/group_filter',
          query: {
            select_filter: 'desc_sinco_1,value',
            schema: 'public',
            table: 'enoe_sinco',
            cve_ent: '00',
            indicator: 'shsinco1emp_nac',
            orderdesc: 'value',
            limit: 10
          }
        }
      ]}
      colorScale={colorScale}
      formatValueFn={(d) => format('.2%')(d / 100)}
      formatDataFn={(data) => {
        const [elements] = data;
        return {
          name: 'Data Elements',
          children: elements.map((element) => {
            return {
              name: element.desc_sinco_1,
              value: element.value,
              pct: element.value
            }
          })
        };
      }}
    />
  );
}

EmploymentTreemap.defaultProps = {
  data: {
    name: '',
    children: []
  }
}