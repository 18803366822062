import React from 'react';
import { Section } from "../../components/Section";
import Sankey from "../../components/Sankey";
import { EmploymentTreemap } from "../../sections/state/detail/EmploymentTreemap";
import { withDataFetch } from "../../components/withDataFetch";

import { EducationLevel } from "../../sections/state/detail/EducationLevel";
import { BoxPlotSection } from "../../components/BoxPlotSection";
import { Age } from "../../sections/state/detail/Age";
import { Gender } from "../../sections/state/detail/Gender";
import { WorkingDay } from "../../sections/state/detail/WorkingDay";
import { Salary } from "../../sections/state/detail/Salary";
import { toSankey } from '../../helpers/dataTransformations'
import { useChartDictionary } from "../../helpers/useChartDictionary";

import salary_icon from '../../images/job_salary.png';
import education_icon from '../../images/job_educativo.png';
import age_icon from '../../images/job_edad.png';
import jornada_icon from '../../images/job_jornada.png';

import { formatToPercentage } from "../../helpers/formatters";

const SankeyWithFetch = withDataFetch(Sankey);

export const StateDetail = ({ cve_ent = '09', state = {} }) => {
  const chartsDictionary = useChartDictionary();
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <Section
            title={chartsDictionary['treemap_ocupacion']?.details?.title}
            id='ocupation-treemap'
            delimitation={state?.label}
            information={chartsDictionary['treemap_ocupacion']?.details}
          >
            <EmploymentTreemap cve_ent={cve_ent} />
          </Section>
        </div>

        <div className="col-sm-12 col-md-6">
          <Section
            title={chartsDictionary['sankey_ocupaciones_habilidades_sinco1']?.details?.title}
            id='ocupation-sankey'
            delimitation={state?.label}
            information={chartsDictionary['sankey_ocupaciones_habilidades_sinco1']?.details}
          >
            <SankeyWithFetch
              height={500}
              isEmpty={(data) => {
                const [elements] = data;
                return elements.nodes.length === 0
                || elements.edges.length === 0;
              }}
              queries={[
                {
                  url: 'network_group_filter',
                  query: {
                    schema: 'public',
                    table: 'skills_sinco_1',
                    categories: 'sincos,skills',
                    cve_ent
                  }
                }
              ]}
              formatValueFn={d => formatToPercentage(d / 100)}
              formatDataFn={(data) => {
                const [elements] = data;
                return toSankey(elements);
              }}
            />
          </Section>
        </div>

        <div className="col-sm-12 col-md-12">
          <Section
            title="Ocupaciones con mayor demanda"
            delimitation={state?.label}
            information={{
                ...chartsDictionary['barplot_salario']?.details,
                source: null,
                caption: null
            }}
          >
            <BoxPlotSection
              title={chartsDictionary['barplot_salario']?.details?.title}
              id='salary'
              meta={state?.label}
              icon={<img alt='Salary Icon' src={salary_icon} />}
              information={chartsDictionary['barplot_salario']?.details}
            >
              <Salary cve_ent={cve_ent} />
            </BoxPlotSection>

            <BoxPlotSection
              title={chartsDictionary['barplot_educacion']?.details?.title}
              id='education'
              meta={state?.label}
              icon={<img alt='Education Icon' src={education_icon} />}
              information={chartsDictionary['barplot_educacion']?.details}
            >
              <EducationLevel cve_ent={cve_ent} />
            </BoxPlotSection>

            <BoxPlotSection
              title={chartsDictionary['barplot_edad']?.details?.title}
              id='age'
              meta={state?.label}
              icon={<img alt='Age Icon' src={age_icon} />}
              information={chartsDictionary['barplot_edad']?.details}
            >
              <Age cve_ent={cve_ent} />
            </BoxPlotSection>

            <BoxPlotSection
              title={chartsDictionary['barplot_genero']?.details?.title}
              id='genero'
              meta={state?.label}
              information={chartsDictionary['barplot_genero']?.details}
            >
              <Gender cve_ent={cve_ent} />
            </BoxPlotSection>

            <BoxPlotSection
              title={chartsDictionary['Jornada']?.details?.title}
              id='jornada'
              meta={state?.label}
              icon={<img alt='Jornada Icon' src={jornada_icon} />}
              information={chartsDictionary['Jornada']?.details}
            >
              <WorkingDay cve_ent={cve_ent} />
            </BoxPlotSection>
          </Section>
        </div>

      </div>
    </div>
  )
}