export const arrColors = [
  '#ff5a47',
  '#ff7e70',
  '#ff9400',
  '#f7b05e',
  '#849ba4',
  '#a7b8be',
  '#3366c7',
  '#5a80d0',
  '#ffb1a8',
  '#809bda'
];

export const colors = {
  'Educacion Basica': '#ff5a47',
  'Educacion Media': '#ff7e70',
  'Educacion Tecnica': '#ff9400',
  'Educacion Superior': '#f7b05e',
  'No Definido': '#849ba4',
  'Those Days': '#a7b8be',
  'Memories on the wall': '#3366c7',
  'Places where I': '#5a80d0',
  'Across the blue': '#ffb1a8',
  'How it all changed': '#809bda'
};

export const spanishColorToRGB = (color) => {
  switch (color) {
    case 'amarillo': return '#ff9800';
    case 'naranja': return '#ff5722';
    case 'verde': return '#859900';
    case 'rojo': return '#e51c23';
  }
  return '#222';
}