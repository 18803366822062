import React from 'react';
import styled from '@emotion/styled'
import { formatToComma, formatToTwoDecimals  } from "../helpers/formatters";
import {OverviewRow} from "./OverviewRow";
import {spanishColorToRGB} from "../helpers/colors";
import {format} from "d3-format";

const OverviewTable = styled.table`
  font-size: 14px;
  width: 100%;
  tbody>tr>td {
    padding: 1rem 0.5rem;
  }
  tr>th {
    font-weight: normal;
  }
  tr td:nth-child(1) {
    color: #343e56;
    font-weight: normal;
  }
  tr td:nth-child(3) {
    text-align: right;
    color: #676767;
    font-weight: normal;
  }
`;

export const OverviewSection = ({ data }) => {
  const {
    vacant = 0,
    occupiedCount = 0,
    unoccupiedCount = 0,
    occupiedIndex = 0,
    unoccupiedIndex = 0
  } = data;
  return (
    <div style={{ padding: '1rem' }}>
    <OverviewTable className="table">
      <thead>
      <tr>
        <th colSpan='2'>Indicador</th>
        <th style={{ textAlign: 'right' }}>Valor</th>
      </tr>
      </thead>
      <tbody>
      <OverviewRow
        id={'vacantes-row'}
        title={data?.dictionary['vacantes']?.label}
        details={data?.dictionary['vacantes']?.details}
        color={spanishColorToRGB(data['vacantes']?.color)}
        value={format(',')(data['vacantes']?.value)}
      />
      <OverviewRow
        id={'empleo-row'}
        title={data?.dictionary['empleo']?.label}
        details={data?.dictionary['empleo']?.details}
        color={spanishColorToRGB(data['empleo']?.color)}
        value={format(',')(data['empleo']?.value)}
      />
      <OverviewRow
        id={'desempleo-row'}
        title={data?.dictionary['desempleo']?.label}
        details={data?.dictionary['desempleo']?.details}
        color={spanishColorToRGB(data['desempleo']?.color)}
        value={format(',')(data['desempleo']?.value)}
      />
      <OverviewRow
        id={'rate-desempleo-row'}
        title={data?.dictionary['tasa_desempleo']?.label}
        details={data?.dictionary['tasa_desempleo']?.details}
        color={spanishColorToRGB(data['tasa_desempleo']?.color)}
        value={format('.2%')(data['tasa_desempleo']?.value / 100)}
      />
      <OverviewRow
        id={'sh-informal-row'}
        title={data?.dictionary['sh_informal']?.label}
        details={data?.dictionary['sh_informal']?.details}
        color={spanishColorToRGB(data['sh_informal']?.color)}
        value={format('.2%')(data['sh_informal']?.value / 100)}
      />

      </tbody>
    </OverviewTable>
    </div>
  );
}