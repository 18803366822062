import React from 'react';
import { useSearchParams } from "react-router-dom";
import { NationalOverview } from "./NationalOverview";
import { NationalDetail } from "./NationalDetail";
import {ErrorBoundary} from "react-error-boundary";

export const National = (props) => {
  let [searchParams] = useSearchParams();
  const viewType = searchParams.get('type');

  if (viewType === 'detallada') {
    return <ErrorBoundary><NationalDetail /></ErrorBoundary>;
  }

  if (viewType === 'panorama' || viewType === null) {
    return <ErrorBoundary FallbackComponent={() => <h1>poo</h1>}>
      <NationalOverview />
    </ErrorBoundary>;
  }
}