import React from 'react';
import styled from '@emotion/styled'
import { format } from "d3-format";
import {OverviewRow} from "../../../components/OverviewRow";
import {spanishColorToRGB} from "../../../helpers/colors";

const OverviewTable = styled.table`
  font-size: 12px;
  width: 100%;
  tbody>tr>td {
    padding: 0.5rem;
  }
  tr>th {
    font-weight: normal;
  }
  tr td:nth-child(1) {
    color: #343e56;
    font-weight: normal;
  }
  tr td:nth-child(3) {
    text-align: right;
    color: #676767;
    font-weight: normal;
  }
`;

export const OverviewSection = ({ data }) => {
  return (
    <div style={{ padding: '1rem' }}>
      <OverviewTable className="table">
        <thead>
        <tr>
          <th colSpan='2'>Indicador</th>
          <th style={{ textAlign: 'right' }}>Valor</th>
        </tr>
        </thead>
        <tbody>
        <OverviewRow
          id={'empleo-row'}
          title={data?.dictionary['empleo']?.label}
          details={data?.dictionary['empleo']?.details}
          color={spanishColorToRGB(data['empleo']?.color)}
          value={format(',')(data['empleo']?.value)}
        />
        <OverviewRow
          id={'gtempleo-row'}
          title={data?.dictionary['gtempleo']?.label}
          details={data?.dictionary['gtempleo']?.details}
          color={spanishColorToRGB(data['gtempleo']?.color)}
          value={format('.2%')(data['gtempleo']?.value / 100)}
        />
        <OverviewRow
          id={'tasa_desempleo-row'}
          title={data?.dictionary['tasa_desempleo']?.label}
          details={data?.dictionary['tasa_desempleo']?.details}
          color={spanishColorToRGB(data['tasa_desempleo']?.color)}
          value={format('.2%')(data['tasa_desempleo']?.value / 100)}
        />
        <OverviewRow
          id={'mean_imput-row'}
          title={data?.dictionary['mean_imput']?.label}
          details={data?.dictionary['mean_imput']?.details}
          color={spanishColorToRGB(data['mean_imput']?.color)}
          value={format('$.2f')(data['mean_imput']?.value)}
        />
        <OverviewRow
          id={'gtrmean_imput-row'}
          title={data?.dictionary['gtrmean_imput']?.label}
          details={data?.dictionary['gtrmean_imput']?.details}
          color={spanishColorToRGB(data['gtrmean_imput']?.color)}
          value={format('.2%')(data['gtrmean_imput']?.value)}
        />
        <OverviewRow
          id={'sh_edniv_ins33-row'}
          title={data?.dictionary['sh_edniv_ins33']?.label}
          details={data?.dictionary['sh_edniv_ins33']?.details}
          color={spanishColorToRGB(data['sh_edniv_ins33']?.color)}
          value={format('.2%')(data['sh_edniv_ins33']?.value / 100)}
        />
        <OverviewRow
          id={'sh_informal-row'}
          title={data?.dictionary['sh_informal']?.label}
          details={data?.dictionary['sh_informal']?.details}
          color={spanishColorToRGB(data['sh_informal']?.color)}
          value={format('.2%')(data['sh_informal']?.value / 100)}
        />
        <OverviewRow
          id={'Dt_sh_informal-row'}
          title={data?.dictionary['Dt_sh_informal']?.label}
          details={data?.dictionary['Dt_sh_informal']?.details}
          color={spanishColorToRGB(data['Dt_sh_informal']?.color)}
          value={format('.2%')(data['Dt_sh_informal']?.value / 100)}
        />
        <OverviewRow
          id={'sh_brecha-row'}
          title={data?.dictionary['sh_brecha']?.label}
          details={data?.dictionary['sh_brecha']?.details}
          color={spanishColorToRGB(data['sh_brecha']?.color)}
          value={format('.2%')(data['sh_brecha']?.value / 100)}
        />
        <OverviewRow
          id={'share_inact-row'}
          title={data?.dictionary['share_inact']?.label}
          details={data?.dictionary['share_inact']?.details}
          color={spanishColorToRGB(data['share_inact']?.color)}
          value={format('.2%')(data['share_inact']?.value / 100)}
        />
        <OverviewRow
          id={'share_mismatch-row'}
          title={data?.dictionary['share_mismatch']?.label}
          details={data?.dictionary['share_mismatch']?.details}
          color={spanishColorToRGB(data['share_mismatch']?.color)}
          value={format('.2%')(data['share_mismatch']?.value / 100)}
        />
        <OverviewRow
          id={'sh_nini-row'}
          title={data?.dictionary['sh_nini']?.label}
          details={data?.dictionary['sh_nini']?.details}
          color={spanishColorToRGB(data['sh_nini']?.color)}
          value={format('.2%')(data['sh_nini']?.value / 100)}
        />
        </tbody>
      </OverviewTable>
    </div>
  );
}
