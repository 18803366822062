import React from 'react';
import topoJSON from '../json/mx.json';
import { merge, feature } from "topojson-client";
import { geoMercator, geoPath } from "d3-geo";
import { withSize } from 'react-sizeme'
import styled from "@emotion/styled";
import {formatToTwoDecimals} from "../helpers/formatters";

export class Cloropeth extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      projection: null,
      current: null
    };
  }

  setProjection = () => {
    const { size: { width } } = this.props;
    const { height } = this.props;
    const land = merge(topoJSON, topoJSON.objects.states.geometries);
    const projection = geoMercator().fitSize([width, height], land);
    this.setState(() => ({ projection }))
  }

  componentDidMount() {
    this.setProjection();
  }



  renderState = (feature) => {
    const { projection } = this.state;
    const path = geoPath().projection(projection);
    return (
      <path
        d={path(feature)}
      >
        <title>{JSON.stringify(feature.properties)}</title>
      </path>
    )
  }

  handleMouseEnter = (e, properties) => {
    const [x, y] = e.target.dataset.centroid.split(',');
    this.setState({
      current: { x, y, data: properties }
    });
  }

  handleMouseOut = (e) => {
    this.setState({ current: null });
  }

  getScale = () => {
    const { data, scale } = this.props;
    return scale(data);
  }

  renderLand = () => {
    const { size: { width } } = this.props;
    const { height } = this.props;
    const land = merge(topoJSON, topoJSON.objects.states.geometries);
    const projection = geoMercator().fitSize([width, height], land);
    const { data } = this.props;
    if (!projection) return;
    const features = feature(topoJSON, topoJSON.objects.states).features;
    const path = geoPath().projection(projection);
    const colorScale = this.getScale();
    const dataMap = data.reduce((acc, val) => {
      acc[val.id] = val;
      return acc;
    }, {});
    return features.map((feature) => {
        const id = feature.properties?.state_code;
        const value = dataMap[id]?.value;
        const fillColor = colorScale(value);
        return (
          <path
            stroke="#323d53"
            d={path(feature)}
            fill={fillColor}
            data-centroid={path.centroid(feature)}
            onMouseLeave={this.handleMouseOut}
            onMouseEnter={e => this.handleMouseEnter(e, { feature, data: dataMap[id] })}>
          </path>
        );
      }
    );
  }


  render () {
    const { height, size: { width }, formatValueFn } = this.props;
    const { current } = this.state;
    const colorScale = this.getScale();
    return (
      <div style={{ width: '100%', position: 'relative' }}>
        {
          current
            ? <FeatureDetails x={current.x} y={current.y} backgroundColor={colorScale(current?.data?.data?.value)}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: '1rem', width: 15, height: 15, background: colorScale(current?.data?.data?.value), borderRadius: '500%' }} />
                  <div>
                      <span>{current.data.feature.properties.state_name} - {formatValueFn(current.data.data.value)}</span>
                  </div>
              </div>
            </FeatureDetails>
            : null
        }
        <svg width={width} height={height}>
          <g className='features'>
            {this.renderLand()}
          </g>
        </svg>
      </div>
    );
  }
}

const FeatureDetails = styled.div`
  position: absolute;
  pointer-events: none;
  transition: transform 0.25s ease;
  transform: ${props => `translate(${props.x}px, ${props.y}px)`};
  background: #fff;
  padding: 5px 10px;
    border: 1px solid #222;
  border-radius: 20px;
  font-size: 14px;
  color: #fff;
  span {
      color: #222;
  }  
`;

Cloropeth.defaultProps = {
  size: {
    width: 0,
    height: 0
  },
  width: 1000,
  height: 800,
  scale: null,
  featureKeyDict: {},
  featureValueFn: (d) => d.value,
  formatValueFn: (d) => d
};

export default withSize()(Cloropeth)
