import React, {useEffect} from "react";
import info_icon from "../images/iconmonstr-info-thin.svg";
import ReactTooltip from "react-tooltip";

export const OverviewRow = ({ id, title, value, color, details }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [details?.source]);
  return (
    <tr>
      <td>
        <img height={20} width={20} alt='Source Information' data-tip data-for={id} src={info_icon} />
        <ReactTooltip id={id} aria-haspopup={'true'}>
          <div style={{ width: 400 }}>
            <p>Fuente: {details?.source}</p>
            <p>{details?.caption}</p>
          </div>
        </ReactTooltip>
      </td>
      <td>{title}</td>
      <td style={{ color, fontWeight: 'bold' }}>
        {value}
      </td>
    </tr>
  );
};
