import { useState, useEffect } from "react";
import axios from "../api/axiosInstance";

export function useCityDictionary() {
  const [citiesDictionary, setCitiesDictionary] = useState({});
  useEffect(() => {
    axios.get('/data_to_filters', { params: { categories: 'cve_cda' }})
      .then((res) => {
        const dictionary = res.data[0].cve_cda.reduce((acc, val) => {
          acc[val.value] = val;
          return acc;
        }, {});
        setCitiesDictionary(dictionary);
      })
      .catch((err) => console.log(err));
  }, []);

  return citiesDictionary;
}

