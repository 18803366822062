import React from 'react';
import { useSearchParams } from "react-router-dom";
import {StateDetail} from "./StateDetail";
import {StateOverview} from "./StateOverview";
import {useStateDictionary} from "../../helpers/useStateDictionary";

export const State = (props) => {
  const statesDictionary = useStateDictionary();
  let [searchParams] = useSearchParams();
  const viewType = searchParams.get('type');
  const cve_ent = searchParams.get('cve_ent');


  if (viewType === 'detallada') {
    return <StateDetail cve_ent={cve_ent} state={statesDictionary[cve_ent]} />;
  }

  if (viewType === 'panorama' || viewType === null) {
    return <StateOverview cve_ent={cve_ent} state={statesDictionary[cve_ent]} />;
  }
}