import React from 'react';
import { useSearchParams } from "react-router-dom";
import { CityDetail } from "./CityDetail";
import { CityOverview } from "./CityOverview";
import {useCityDictionary} from "../../helpers/useCityDictionary";

export const City = (props) => {
  const cityDictionary = useCityDictionary();
  let [searchParams] = useSearchParams();
  const viewType = searchParams.get('type');
  const cve_cda = searchParams.get('cve_cda');
  const city = cityDictionary[cve_cda];

  if (viewType === 'detallada') {
    return <CityDetail cve_cda={cve_cda} city={city} />;
  }

  if (viewType === 'panorama' || viewType === null) {
    return <CityOverview cve_cda={cve_cda} city={city} />;
  }
}