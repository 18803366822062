import React, { Component } from 'react';
import { scaleBand, scaleLinear  } from 'd3-scale';
import { format } from 'd3-format';
import { stack } from 'd3-shape';
import {withSize} from "react-sizeme";
import styled from "@emotion/styled";

const BoxPlotLabel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  height: 100%;
  text-align: right;
  justify-content: center;
  .label-title {
    font-size: 1rem;
    font-weight: 500;
  }
  .label-description {
    font-size: 10px;
    line-height: 10px;
    font-weight: 300;
  }
`;

const BoxPlotContext = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end; 
  padding: 2rem 0;
  .key-context {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }
  .key-color {
    width: 20px;
    margin-right: 5px;
    border-radius: 5px;
    height: 10px;
  }
  .key-name {
    font-size: 12px;
    font-weight: 300;
  }
`



export class BoxPlot extends Component {
  constructor (props) {
    super(props);
    this.state = {
      focusKey: null
    }
  }

  get yScale () {
    const { data, xValueFn } = this.props;
    const height = this.height;
    const values = data.map(xValueFn);
    return scaleBand()
      .domain(values)
      .range([0, height])
      // .paddingInner(0.7)
      .padding(0.8)
  }

  get xScale () {
    const width = this.width;
    return scaleLinear()
      .domain([0, 1])
      .range([0, width]);
  }

  get width () {
    const { size: { width }, margin } = this.props;
    return  width - margin.left - margin.right;
  }

  get height () {
    const { height, margin } = this.props;
    return  height - margin.top - margin.bottom;
  }

  renderBoxPlotGroups = (elements, index) => {
    const { colors, margin } = this.props;
    const { focusKey } = this.state;
    const yScale = this.yScale;
    const xScale = this.xScale;
    return (
      <g key={elements.key} data-key={elements.key} fill={colors[elements.key]}>
        {elements.map((element, id) => {
          const [x0, x1] = element;
          const data = element.data;
          const width = xScale((x1 - x0));
          const key = elements.key;
          const value = data[key];
          const yOffset = yScale.padding(0)(data.label) + (yScale.padding(0).bandwidth() / 2)
          return (
            <g key={id} transform={`translate(${0}, ${yOffset})`}>
              <rect
                className='data-rect'
                x={xScale(x0)}
                y={0}
                stroke='#fdf6e3'
                fillOpacity={focusKey === null ? 1 : focusKey === key ? 1 : 0.2}
                height={yScale.padding(0.8).bandwidth()}
                width={width}
                >
                <title>{key} {format('.1%')(value)}</title>
              </rect>
              {width < 40
               ? null
               : <text
                   x={(xScale(x0)) + (xScale(value) / 2)}
                   y={12}
                   fontSize='12'
                   fill='#fff'
                   textAnchor='middle'
                   >
                   {format('.1%')(value)}
                </text>
              }
            </g>
          );
        })}
      </g>
    )
  }

  renderBoxKeys = (element, index) => {
    const { colors } = this.props;
    return (
        <div
            key={index}
            className='key-context'
            data-box-key={element}
            onMouseEnter={this.handleKeyMouseEnter}
            onMouseLeave={this.handleKeyMouseOut}
        >
          <div className='data-rect key-color' style={{ background: colors[element] }} />
          <span className='key-name'>{element}</span>
        </div>
    );
  }

  handleKeyMouseEnter = (e, d) => {
    const boxKey = e.currentTarget.dataset['boxKey'];
    this.setState({ focusKey: boxKey });
  }

  handleKeyMouseOut = (e) => {
    this.setState({ focusKey: null });
  }

  render () {
    const { size: { width }, data, height, margin, boxKeys } = this.props;
    const yScale = this.yScale;
    const xScale = this.xScale;

    const stackFn = stack().keys(boxKeys);
    const series = stackFn(data);
    return (
      <div>
        <svg width={width} height={height} style={{ background: '#f9fafa' }}>
          <BandAxis tx={0} ty={margin.top} scale={yScale} margin={margin} />
          <LinearAxis scale={xScale} tx={margin.left} ty={height - margin.bottom} formatValue={d => format('.0%')(d)} />
          <LinearGrid scale={xScale} tx={margin.left} ty={margin.top} size={this.height} />
          <g className='box-plot-data' transform={`translate(${margin.left}, ${margin.top})`}>
            {series.map(this.renderBoxPlotGroups)}
          </g>
        </svg>
        <BoxPlotContext>
          {boxKeys.map(this.renderBoxKeys)}
        </BoxPlotContext>
      </div>
    );
  }
}

BoxPlot.defaultProps = {
  height: 500,
  boxKeys: [],
  size: {
    width: 0
  },
  data: [],
  margin: {
    top: 20,
    right: 30,
    bottom: 30,
    left: 200
  },
  xValueFn: d => d.label,
  focusKey: null,
  colors: {},
  formatValue: d => format('.1%')(d)
  // yValueFn: d => d
};

const LinearGrid = ({ scale, tx, ty, size, ...props }) => {
  const ticks = scale.ticks();
  return (
    <g className='linear-grid grid' transform={`translate(${tx}, ${ty})`}>
      {
        ticks.map((d, i) => {
          return (
            <g key={i} transform={`translate(${scale(d)}, 0)`}>
              <line style={{ shapeRendering: 'crispedges' }} stroke='#cad3d7' x1={0} y1={0}  x2={0} y2={size} strokeDasharray='2' />
            </g>
          );
        })
      }
    </g>
  );
}

const BandAxis = ({ scale, tx, ty, ...props }) => {
  const ticks = scale.domain();
  return (
    <g className='band-axis axis' transform={`translate(${0}, ${ty})`}>
      {ticks.map((d, i) => {
        return (
          <g key={i} transform={`translate(0, ${scale.padding(0)(d)})`}>
            <foreignObject x={0} width={props.margin.left} height={scale.padding(0).bandwidth()}>
              <BoxPlotLabel>
                <span className="label-title">OCUPACIÓN {i + 1}</span>
                <span className="label-description">{d}</span>
              </BoxPlotLabel>
            </foreignObject>
          </g>
        )
      })}
    </g>
  )
};

const LinearAxis = ({ scale, tx, ty, formatValue, ...props }) => {
  const ticks = scale.ticks();
  return (
    <g className='linear-axis axis' transform={`translate(${tx}, ${ty})`}>
      {
        ticks.map((d, i) => {
          return (
            <g key={i} transform={`translate(${scale(d)}, 0)`}>
              <text y='9' dy='0.71em' fontSize={12} textAnchor='middle'>{formatValue(d)}</text>
            </g>
          );
        })
      }
    </g>
  );
};

export default withSize()(BoxPlot);