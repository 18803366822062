import React, {useEffect} from "react";
import styled from "@emotion/styled";
import info_icon from "../images/iconmonstr-info-thin.svg";
import ReactTooltip from "react-tooltip";

const Styled = styled.div`
  padding: 55px;
  padding-top: 2rem;
  padding-bottom: 0;
  .boxplot-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .boxplot-title {
    display: flex;
    font-weight: normal;
    align-items: center;
  }
  .boxplot-title img { 
    height: 70px;
  }
  .boxplot-title h3 {
    margin-right: 1rem;
    margin-bottom: 0;
  }
  .boxplot-meta {
      font-size: 14px;
      color: #475055;
    min-width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .boxplot-meta img {
    height: 20px;
    width: 20px;
  }
`

export const BoxPlotSection = ({ id, icon, title, meta, timeRange, children, information }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [information]);
  return (
    <Styled>
      <div className='boxplot-header'>
        <div className='boxplot-title'>
          {icon}
          <h3>{title}</h3>
        </div>
        <div className='boxplot-meta'>
          {
            information
              ? <img alt='Source Information' data-tip data-for={id} src={info_icon} />
              : null
          }
          <span style={{ margin: '0 1rem'}}>{information?.temporalidad}</span>
          <span>{meta}</span>
        </div>
      </div>
      {children}
      {
        information
          ? <ReactTooltip id={id} aria-haspopup='true' >
            <div style={{ width: 400 }}>
              <p>Fuente: {information?.source}</p>
              <p>{information?.caption}</p>
            </div>
          </ReactTooltip>
          : null
      }
    </Styled>
  );
}
