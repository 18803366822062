import React, {useState, Component, useEffect} from 'react'
import JSURL from 'jsurl'
import axios from "../api/axiosInstance";
import Select from 'react-select'
import styled from '@emotion/styled'
import {useLocation, useSearchParams} from "react-router-dom";
import { useNavigate } from "react-router-dom";


const Container = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SelectContainer = styled.div`
  width: 250px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const ButtonContainer = styled.div`
  width: 240px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Button = styled.button`
  &:disabled {
    background: #9fa8bb;
  }
  background: #3366c7;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  height: 44px;
  @media (max-width: 768px) {
    border-radius: 0;
  }
`;

const delimitations = [
  { value: 'nacional', label: 'Nacional' },
  { value: 'estatal', label: 'Estatal' },
  { value: 'ciudad', label: 'Ciudad' }
]

const optionStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 20,
  })
}
const contolStyles = {
  height: 44,
  width: '100%'
}

const leftSelectStyles = {
  ...optionStyles,
  control: (provided) => ({
    ...provided,
    ...contolStyles,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  }),
}

const middleSelectStyles = {
  ...optionStyles,
  control: (provided) => ({
    ...provided,
    ...contolStyles,
    borderRadius: 0
  }),
}

const rightSelectStyles = {
  ...optionStyles,
  control: (provided) => ({
    ...provided,
    ...contolStyles,
    borderRadius: 0
  }),
}

function useQueryParam (key) {
  let [searchParams, setSearchParams] = useSearchParams();
  let paramValue = searchParams.get(key);

  let value = React.useMemo(() => JSURL.parse(paramValue), [paramValue]);

  let setValue = React.useCallback((newValue, options) => {
    let newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, JSURL.stringify(newValue));
    setSearchParams(newSearchParams, options);
  },
  [key, searchParams, setSearchParams]
);

  return [value, setValue];
}

export const Filters = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let location = useLocation();
  let navigate = useNavigate();

  const urlDelimitation = searchParams.get('delimitation');
  const [delimitation, setDelimitation] = useState(delimitations.filter(d => d.value === urlDelimitation).pop());
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);


  function queryIsDisabled() {
    if (delimitation?.value === 'nacional') return false;
    if (delimitation?.value === 'estatal' && state) return false;
    if (delimitation?.value === 'ciudad' && city) return false;
    return true;
  }

  useEffect(() => {
    const getStates = axios.get('/data_to_filters', { params: { categories: 'cve_ent' }});
    const getCities = axios.get('/data_to_filters', { params: { categories: 'cve_cda' }});
    Promise.all([getStates, getCities])
      .then((res) => {
        const [statesResponse, citiesResponse] = res;
        const states = statesResponse.data[0].cve_ent
          .map((state) => {
            return {
              label: state.label,
              value: state.value,
              cities: state.details.cve_cda
            }
          });
        const cities = citiesResponse.data[0].cve_cda
          .map((city) => {
            return {
              label: city.label,
              value: city.value
            }
          })
        setStateOptions(states)
        setCityOptions(cities)
        if (searchParams.get('cve_ent')) {
          setState(states.filter(d => d.value === searchParams.get('cve_ent')).pop())
        }
        if (searchParams.get('cve_cda')) {
          setCity(cities.filter(d => d.value === searchParams.get('cve_cda')).pop())
        }
      })
      .catch((err) => console.log(err))
  }, [])

  const activeDelimitation = searchParams.get('delimitation');
  return (
    <Container>
      <SelectContainer>
        <Select
          placeholder="Delimitacion"
          styles={leftSelectStyles}
          options={delimitations}
          isClearable
          onChange={(option) => {
            setDelimitation(option);
            if (!(state && (option?.value === 'estatal' || option?.value === 'ciudad'))) {
              setState(null);
            }
            setCity(null);
          }}
          value={delimitation}
        />
      </SelectContainer>
      <SelectContainer>
        <Select
          isDisabled={delimitation?.value === 'nacional'}
          placeholder="Estado"
          styles={middleSelectStyles}
          options={stateOptions}
          isClearable
          onChange={(option) => {
            setState(option);
            setCity(null);
          }}
          value={state}
        />
      </SelectContainer>
      <SelectContainer>
        <Select
          isDisabled={delimitation?.value === 'nacional' || delimitation?.value === 'estatal'}
          placeholder="Ciudad"
          styles={rightSelectStyles}
          options={cityOptions}
          filterOption={(option) => {
            if (state === null) return true;
            return state?.cities.includes(option.value)
          }}
          isClearable
          onChange={(option) => {
            setCity(option);
          }}
          value={city}
        />
      </SelectContainer>
      <ButtonContainer>
        <Button
          disabled={queryIsDisabled()}
          onClick={e => {
            const p = new URLSearchParams()
            const viewType = searchParams.get('type');
            const params = `type=${viewType ? viewType : 'panorama'}`;
            if (delimitation?.value === 'nacional') {
              const v = searchParams.get('type');
              p.set('delimitation', delimitation?.value)
              p.set('type', v ? v : 'panorama')
              navigate(`/app?${p.toString()}`, { replace: false })
            }
            if (delimitation?.value === 'estatal') {
              const v = searchParams.get('type');
              p.set('delimitation', delimitation?.value)
              p.set('cve_ent', state?.value);
              p.set('type', v ? v : 'panorama')

              navigate(`/app/estatal?${p.toString()}`, { replace: false })
            }
            if (delimitation?.value === 'ciudad') {
              const v = searchParams.get('type');
              p.set('delimitation', delimitation?.value)
              p.set('cve_ent', state?.value);
              p.set('cve_cda', city?.value);
              p.set('type', v ? v : 'panorama')

              navigate(`/app/ciudad-autonombrada?${p.toString()}`, { replace: false })
            }
            // setSearchParams({
            //   delimitation: delimitation?.value,
            //   cve_ent: state?.value,
            //   cve_cda: city?.value
            // })
          }}>Buscar</Button>
      </ButtonContainer>
    </Container>
  )

}
