import React, { useState } from 'react';
import { ErrorBoundary } from "react-error-boundary";
import axios from "../api/axiosInstance";
import styled from "@emotion/styled";

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const StateInformation = styled.div`
  background: ${props => props.color || '#222'};
  border: ${props => props.border || 'none'}; 
  color: #fff;
  min-height: 400px;
  margin: 1rem;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    font-size: 14px;
    font-weight: bold;
    color: #585858;
  }
`;

export function withDataFetch (WrappedComponent) {
  function WithDataFetch ({ queries = [], formatDataFn = () => [], buildDataFn = () => [], isEmpty = () => false, ...props }) {
    const [response, setResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(null);

    React.useEffect(() => {
      setIsLoading(true);
      const requests = queries.map(({ url, query }) => {
        return axios.get(`${url}`, { params: query })
      })
      Promise.all(requests)
        .then((res) => {
          setIsLoading(false);
          const data = res.map(r => r.data);
          setResponse(data);
        })
        .catch((err) => {
          console.error(`The error for ${getDisplayName(WrappedComponent)} is `, err)
          setIsLoading(false)
          setHasError(err)
        })
    }, [JSON.stringify(queries)]);

    if (hasError) {
      return (
        <StateInformation color='none' border='2px dashed #d16969'>
          <span style={{ color: '#d16969' }}>An error was encountered.</span>
        </StateInformation>
      );
    }

    if (isLoading || response === null) {
      return (
        <StateInformation color='#e3e3e3'>
          <div className="loader" />
        </StateInformation>
      )
    }

    if (isEmpty(response)) {
      return (
        <StateInformation color='#fbfbfb'>
          <span>No existen datos.</span>
        </StateInformation>
      )
    }

    const formattedData = formatDataFn(response);
    return (
      <ErrorBoundary FallbackComponent={() => <div>An error ocurred</div>}>
        <WrappedComponent
          {...props}
          data={formattedData}
          loading={isLoading}
        />
      </ErrorBoundary>
    );
  };
  WithDataFetch.displayName = `WithDataFetch(${getDisplayName(WrappedComponent)})`;
  return WithDataFetch;
}