import React from 'react';
import Treemap from "../../../components/Treemap";
import { colors, arrColors } from "../../../helpers/colors";
import {scaleOrdinal} from "d3-scale";
import styled from "@emotion/styled";
import {format} from "d3-format";

const ContextSection = styled.div`
`;

const ContextElement = styled.div`
  display: flex;
  padding: 1rem;
  .element-color {
    width: 40px;
    min-width: 40px;
    height: 15px;
    margin-right: 0.5rem;
    border-radius: 3px;
    border: 1px solid #282c34;
    background: ${props => props.color || '#222' };
  }
  .element-label {
    font-size: 14px;
    line-height: 14px;
  }
`;

export const KeyedTreemap = ({ data }) => {
  const colorScale = scaleOrdinal()
    .domain(data.children.map(d => d.name))
    .range(arrColors);

  return (
    <div className="row no-gutters">
      <div className="col-sm-12 col-md-4" style={{ background: '#f4f4f5'}}>
        <div>
          {data.children.map((tile) => {
            return (
              <ContextElement color={colorScale(tile.name)}>
                <div className='element-color' />
                <div className='element-label'>
                  <span>{tile.name}</span>
                </div>
              </ContextElement>
            );
          })}
        </div>
      </div>
      <div className="col-sm-12 col-md-8 h-100" style={{ background: '#fff', padding: 0 }}>
        <Treemap
          formatValueFn={(d) => format('.2%')(d / 100)}
          height={800}
          colorScale={colorScale}
          data={data}
        />
      </div>
    </div>
  )
}

KeyedTreemap.defaultProps = {
  data: {
    name: '',
    children: []
  }
}