import React from 'react';
import BoxPlot from "../../components/BoxPlot";
import { withDataFetch } from "../../components/withDataFetch";
import { groups } from "d3-array";

const FetchWrapper = withDataFetch(BoxPlot);

const commonQuery = {
  select_filter: 'sinco2,value',
  schema: 'public',
  table: 'enoe_top_sinco',
  cve_ent: '00',
  orderdesc: 'vacantes'
}

const workKeys = [
  'Tiempo Completo',
  'Tiempo Parcial'
]

export const WorkingDay = ({ data }) => {
  return (
    <FetchWrapper
      boxKeys={workKeys}
      colors={{
        'Tiempo Completo': '#f3bf80',
        'Tiempo Parcial': '#8096d5',
      }}
      queries={[
        {
          url: 'data_to_filters',
          query: {
            categories: 'sincos'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            indicator: 'shfull_time'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            indicator: 'shpart_time'
          }
        },
      ]}
      formatDataFn={(data) => {
        const [sincos, ...indicators] = data;
        const sincosElements = sincos[0].sincos;
        const sincosMap = new Map();
        sincosElements.forEach((element) => {
          sincosMap.set(element.value, element.label.trim());
        });

        const joined = indicators
          .map((indicator, index) => {
            return indicator.map((d) => {
              return {
                education: workKeys[index],
                label: sincosMap.get(d.sinco2.toString()),
                value: d.value
              };
            });
          })
          .reduce((acc, val) => [...acc, ...val], [])

        const grouped = groups(joined, d => d.label)
          .map((profession) => {
            const [label, properties] = profession;
            const obj = {};
            properties.forEach((property) => {
              obj[property.education] = property.value / 100;
            });
            return {
              label,
              ...obj
            };
          })
        return grouped;
      }}
    />
  );
}
