import React from 'react';
import { format } from 'd3-format';
import { extent, max } from 'd3-array';
import { scaleBand, scaleLinear } from 'd3-scale';
import { withSize } from 'react-sizeme'


export class AbsoluteBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  get elementHeight() {
    return 50;
  }

  get contextWidth() {
    return 40;
  }

  get xScale () {
    const { hasNegative, data } = this.props;
    const width = this.width;
    const domain = hasNegative
      ? extent(data, d => d.value)
      : [0, max(data, d => d.value)];
    return scaleLinear()
      .domain(domain)
      .range([0, width]);
  }

  get yScale () {
    const { data } = this.props;
    const height = this.height;
    const values = data.map(d => d.label);
    return scaleBand()
      .domain(values)
      .range([height, 0])
      .padding(0.7)
  }

  get width () {
    const { size: { width }, margin } = this.props;
    return  width - margin.left - margin.right;
  }

  get height () {
    const { height, margin } = this.props;
    return height - margin.top - margin.bottom;
  }

  renderBars = (bar, index) => {
    const { positiveValueColor, negativeValueColor, formatValue } = this.props;
    const yScale = this.yScale;
    const xScale = this.xScale;
    const value = bar.value;
    const xOffset = value > 0 ? xScale(0) : xScale(value);
    const width = value > 0 ? xScale(value) - xScale(0) :   xScale(0) - xScale(value) // xScale(value);
    const fill = value > 0 ? positiveValueColor : negativeValueColor;
    return (
      <g
        key={index}
        onMouseEnter={() => this.handleMouseEnter(bar)}
        transform={`translate(${xOffset}, ${yScale(bar.label) - (yScale.bandwidth() / 2)})`}>
        <rect
          shapeRendering='auto'
          data-value={value}
          width={width}
          rx={yScale.bandwidth() / 2}
          height={yScale.bandwidth()}
          fill={fill}
        />
        <text
          textAnchor='end'
          data-size={yScale.bandwidth()}
          y={yScale.bandwidth()}
          dy={'-0.1em'}
          fontSize={yScale.bandwidth()}
          fontWeight='300'
          x={width - 5}>
          {formatValue(value)}
        </text>
      </g>
    );
  }

  handleMouseEnter = (d) => {
  }

  renderLabels = (datapoint, index) => {
    const { margin } = this.props;
    const yScale = this.yScale;
    // yScale.padding(0)
    return (
      <g key={index} transform={`translate(${0}, ${yScale(datapoint.label) - (yScale.padding(0).bandwidth() / 2) })`}>
        <foreignObject width={margin.left - this.contextWidth} height={yScale.padding(0).bandwidth()}>
          <div style={{ lineHeight: '11px', textAlign: 'right', fontSize: 12, padding: '0 2rem' }}>
            {datapoint.label}
          </div>
        </foreignObject>
      </g>
    );
  }

  render() {
    const { xTitle, yTitle, size: { width }, height, margin, data, sortBy, formatValue } = this.props;
    const xScale = this.xScale;
    const yScale = this.yScale;
    return (
      <div style={{ width: '100%' }}>
        <svg width={width} height={height} style={{ background: '#f9fafa' }}>
          <rect x={margin.left - this.contextWidth} y={0} fill='#fff' width={width - margin.left + this.contextWidth} height={height} />
          <rect stroke='#878787' strokeWidth={2} fill='none' rx={4} x={margin.left} y={margin.top} width={this.width} height={this.height} />
          <LinearAxis scale={xScale} formatValue={formatValue} tx={margin.left} ty={height - margin.bottom} width={width} />
          <LinearGridX scale={xScale} tx={margin.left} ty={margin.top} size={this.height} width={width} />
          <LinearGridY scale={yScale} tx={margin.left} ty={margin.top} size={this.width} />
          <g transform={`translate(${30}, ${margin.top})`}>
            <text textAnchor='middle' x={-this.height / 2} transform='rotate(-90)'>{yTitle}</text>
          </g>
          <g transform={`translate(${margin.left}, ${height - 20})`}>
            <text textAnchor='middle' x={this.width / 2}>{xTitle}</text>
          </g>
          <g className='absolute-bar-data' transform={`translate(${margin.left}, ${margin.top})`} shapeRendering='crispedges'>
            {data.sort(sortBy).map(this.renderBars)}
          </g>
          <g className='absolute-bar-labels' transform={`translate(${0}, ${margin.top})`}>
            {data.sort(sortBy).map(this.renderLabels)}
          </g>
        </svg>
      </div>
    );
  }
}

AbsoluteBar.defaultProps = {
  data: [],
  width: 1200,
  height: 600,
  hasNegative: true,
  positiveValueColor: '#ff7e70',
  negativeValueColor: '#f7b05e',
  sortBy: (a, b) => a.value - b.value,
  formatValue: (d) => d,
  xTitle: '',
  yTitle: '',
  size: {
    width: 0,
    height: 0
  },
  margin: {
    top: 20,
    right: 20,
    bottom: 80,
    left: 380
  },
};

const LinearGridX = ({ scale, width, tx, ty, size, ...props }) => {
  const tickCount = width < 720 ? 4 : 8;
  const ticks = scale.ticks(tickCount);
  return (
    <g className='linear-grid grid' transform={`translate(${tx}, ${ty})`}>
      {
        ticks.map((d, i) => {
          return (
            <g key={i} transform={`translate(${scale(d)}, 0)`}>
              <line stroke='#002b36' x1={0} y1={0}  x2={0} y2={size} strokeDasharray='2' />
            </g>
          );
        })
      }
    </g>
  );
}

const LinearGridY = ({ scale, tx, ty, size, ...props }) => {
  const ticks = scale.domain();
  return (
    <g className='linear-grid grid' transform={`translate(${tx}, ${ty})`}>
      {
        ticks.map((d, i) => {
          return (
            <g key={i} transform={`translate(0, ${scale(d)})`}>
              <line stroke='#002b36' x1={0} y1={0}  x2={size} y2={0} strokeDasharray='2' />
            </g>
          );
        })
      }
    </g>
  );
}

const LinearAxis = ({ scale, tx, ty, width, formatValue, ...props }) => {
  const tickCount = width < 720 ? 4 : 8;
  const ticks = scale.ticks(tickCount);
  return (
    <g className='linear-axis axis' transform={`translate(${tx}, ${ty})`}>
      {
        ticks.map((d, i) => {
          return (
            <g key={i} transform={`translate(${scale(d)}, 0)`}>

              <text y='9' dy='0.71em' textAnchor='middle' fontWeight='bold'>{formatValue(d)}</text>
            </g>
          );
        })
      }
    </g>
  );
};

export default withSize()(AbsoluteBar)
