import React from 'react';
import styled from "@emotion/styled";
import logoTrabajo from '../images/trabajo.png';
import logoWorldBank from '../images/logo-wb-header-es.svg';

const Styled = styled.div`
  background: #fff;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  img {
    height: 40px;
  }

  @media (max-width: 768px) {
    img {
      height: 30px;
    }
  }
`

export const TopNavbar = (props) => {
  return (
    <Styled>
      <img alt="Gobierno de Mexico. Trabajo. Servicio Nacional de Empleo." src={logoTrabajo} />
      <img alt="Banco Mundial" src={logoWorldBank} />
    </Styled>
  );
}
