import React from 'react';
import {TopNavbar} from "./TopNavbar";
import {PageNavbar} from "./PageNavbar";
import {PageTitle} from "./PageTitle";
import {Toggle} from "./Toggle";
import {Filters} from "./Filters";
import {PageContainer} from "./PageContainer";
import {Outlet} from "react-router-dom";
import { useSearchParams } from "react-router-dom";

export const Layout = (props) => {
  return (
    <div>
      <TopNavbar />
      <PageNavbar
        title="Tablero de Control"
        subtitle="Herramienta de Información sobre Empleo y Demanda Laboral"
      />
      <PageContainer>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <PageTitle>
                <h2 style={{ fontWeight: 300 }}>Información de empleo y demanda laboral</h2>
                <Toggle />
              </PageTitle>
            </div>
            <div className="col-sm-12">
              <Filters />
            </div>
          </div>
        </div>
        <Outlet />
      </PageContainer>
    </div>
  )
}