import React from 'react';
import BoxPlot from '../../../components/BoxPlot'
import { withDataFetch } from "../../../components/withDataFetch";
import { groups } from "d3-array";

const FetchWrapper = withDataFetch(BoxPlot);

const commonQuery = {
  select_filter: 'sinco2,value',
  schema: 'public',
  table: 'enoe_top_sinco',
  orderdesc: 'vacantes'
};

const educationKeys = [
  'Educación Básica',
  'Educación Media',
  'Educación Técnica',
  'Educación Superior',
  'No Definido'
];

export const EducationLevel = ({ data, cve_ent = '09' }) => {
  return (
    <FetchWrapper
      boxKeys={educationKeys}
      colors={{
        'Educación Básica': '#3366c2',
        'No Definido': '#71828e',
        'Educación Técnica': '#63d991',
        'Educación Media': '#ff5a47',
        'Educación Superior': '#eca95f'
      }}
      queries={[
        {
          url: 'data_to_filters',
          query: {
            categories: 'sincos'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_ent,
            indicator: 'shdniv_ins31'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_ent,
            indicator: 'shdniv_ins32'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_ent,
            indicator: 'shdniv_ins33'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_ent,
            indicator: 'shdniv_ins34'
          }
        },
        {
          url: '/group_filter',
          query: {
            ...commonQuery,
            cve_ent,
            indicator: 'shdniv_ins35'
          }
        },
      ]}
      formatDataFn={(data) => {
        const [sincos, ...indicators] = data;
        const sincosElements = sincos[0].sincos;
        const sincosMap = new Map();
        sincosElements.forEach((element) => {
          sincosMap.set(element.value, element.label.trim());
        });

        const joined = indicators
          .map((indicator, index) => {
            return indicator.map((d) => {
              return {
                education: educationKeys[index],
                label: sincosMap.get(d.sinco2.toString()),
                value: d.value
              };
            });
          })
          .reduce((acc, val) => [...acc, ...val], [])

        const grouped = groups(joined, d => d.label)
          .map((profession) => {
            const [label, properties] = profession;
            const obj = {};
            properties.forEach((property) => {
              obj[property.education] = property.value / 100;
            });
            return {
              label,
              ...obj
            };
          })
        return grouped;
      }}
    />
  );
}