import React from 'react';
import styled from '@emotion/styled'
import { scaleQuantize } from "d3-scale";
import { extent } from "d3-array";
import { schemeBlues } from "d3-scale-chromatic";
import Cloropeth from "../components/Cloropeth";
import { formatToTwoDecimals } from "../helpers/formatters";
import {format} from "d3-format";

const ContextStyled = styled.div`
  margin-top: 1rem;
`
const ContextThreshold = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
  .threshold-color {
    border: 1px solid #585858;
    border-radius: 3px;
    width: 40px;
    height: 15px;
    background: ${props => props.color || '#222' };
  }
  .threshold-range {
    font-size: 14px;
    color: #676767;
  }
`

const Context = ({ thresholds }) => {
  return (
    <ContextStyled>
      {thresholds.map((threshold) => {
        const { color, range } = threshold;
        return (
          <ContextThreshold color={color}>
            <div className='threshold-color' />
            <div className='threshold-range'>
              [{range.map(formatToTwoDecimals).join(',')}]
            </div>
          </ContextThreshold>
        )
      })}
    </ContextStyled>
  );
}

export const LinearMapSection = ({ data }) => {
  const colors = schemeBlues[6];
  const scale = scaleQuantize(extent(data, d => d.value), colors);
  const invertedScale = colors.map((color) => {
    return {
      range: scale.invertExtent(color),
      color
    }
  });
  return (
    <React.Fragment>
      <div className="col-sm-12 col-md-3">
        <Context thresholds={invertedScale} />
      </div>
      <div className="col-sm-12 col-md-9" style={{ background: '#fff', padding: '1rem 0' }}>
        <Cloropeth
          data={data}
          formatValueFn={(d) => format('.2f')(d)}
          height={450}
          scale={() => scale}
        />
      </div>
    </React.Fragment>
  )
}