import React from "react";
import styled from "@emotion/styled";

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 1rem 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  h2 {
    font-weight: 300;
  }
`;