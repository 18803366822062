import React from 'react';
import { Section } from "../../components/Section";
import {TimeSeries} from "../../components/TimeSeries";
import { OverviewSection } from "../../sections/state/overview/OverviewSection";
import {withDataFetch} from "../../components/withDataFetch";
import {KeyedTreemap} from "../../sections/KeyedTreemap";
import AbsoluteBar from "../../components/AbsoluteBar";
import {ScatterContext} from "../../sections/ScatterContext";
import Sankey from "../../components/Sankey";
import Scatter from "../../components/Scatter";
import {format} from "d3-format";
import {toSankey} from "../../helpers/dataTransformations";
import {useChartDictionary} from "../../helpers/useChartDictionary";
import {getTimeRange} from "../../helpers/date";
import {formatToPercentage} from "../../helpers/formatters";

const TimeSerieWithDataFetch = withDataFetch(TimeSeries);
const OverviewSectionWithDataFetch = withDataFetch(OverviewSection);
const KeyedTreemapSectionWithDataFetch = withDataFetch(KeyedTreemap);
const AbsoluteBarWithDataFetch = withDataFetch(AbsoluteBar);
const ScatterWithDataFetch = withDataFetch(Scatter);
const SankeyWithFetch = withDataFetch(Sankey)

export const StateOverview = ({ cve_ent = '09', state = {} }) => {
  const chartsDictionary = useChartDictionary();
  const [startDate, endDate] = getTimeRange;
    return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <Section
            title={chartsDictionary['time_s_1']?.details?.title}
            delimitation={state?.label}
            id='overview'
            information={chartsDictionary['time_s_1']?.details}
          >
            <div className="row no-gutters">
              <div className="col-sm-12 col-md-4" style={{ background: '#f4f4f5'}}>
                <OverviewSectionWithDataFetch
                  queries={[
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent,
                        indicator: 'empleo'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent,
                        indicator: 'gtempleo'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent,
                        indicator: 'tasa_desempleo'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent,
                        indicator: 'mean_imput'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent,
                        indicator: 'gtrmean_imput'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent,
                        indicator: 'sh_edniv_ins33'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent,
                        indicator: 'sh_informal'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent,
                        indicator: 'Dt_sh_informal'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent,
                        indicator: 'sh_brecha'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent,
                        indicator: 'share_inact'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent,
                        indicator: 'share_mismatch'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent,
                        indicator: 'sh_nini'
                      }
                    },
                    {
                      url: '/data_to_filters',
                      query: {
                        categories: 'overview_local'
                      }
                    }
                  ]}
                  formatDataFn={(data) => {
                    const elements = data.reduce((acc, val) => ([...acc, val[0]]), []);
                    const [
                      vacant,
                      growthEmployment,
                      growthRate,
                      salaryMean,
                      growthSalary,
                      workForce,
                      workInformal,
                      workChange,
                      workWedge,
                      inactivityRate,
                      workMismatch,
                      ninis,
                      information
                    ] = elements;
                    const dictionary = information.overview_local.reduce((acc, val) => {
                      acc[val.value] = val;
                      return acc;
                    }, {});
                    return {
                      empleo: vacant,
                      gtempleo: growthEmployment,
                      tasa_desempleo: growthRate,
                      mean_imput: salaryMean,
                      gtrmean_imput: growthSalary,
                      sh_edniv_ins33: workForce,
                      sh_informal: workInformal,
                      Dt_sh_informal: workChange,
                      sh_brecha: workWedge,
                      share_inact: inactivityRate,
                      share_mismatch: workMismatch,
                      sh_nini: ninis,
                      dictionary
                    };
                  }}
                />
              </div>

              <div className="col-sm-12 col-md-8" style={{ background: '#fff'}}>
                <TimeSerieWithDataFetch
                  queries={[
                    {
                      url: '/group_filter',
                      query: {
                        table: 'job_vacancies_ds',
                        schema: 'public',
                        key_group: 'datetime,cve_ent',
                        operations: 'sum',
                          startdate: startDate,
                          enddate: endDate,
                        indicator: 'y',
                        cve_ent
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        table: 'job_vacancies_ds',
                        schema: 'public',
                        key_group: ['datetime', 'cve_ent'].join(','),
                        operations: 'sum',
                          startdate: startDate,
                          enddate: endDate,
                        indicator: 'y_rolling',
                        cve_ent
                      }
                    }
                  ]}
                  isEmpty={(data) => {
                    return data.every((serie) => serie.length === 0)
                  }}
                  xAxisTitle={chartsDictionary['time_s_1']?.details?.xlab}
                  yAxisTitle={chartsDictionary['time_s_1']?.details?.ylab}
                  title={chartsDictionary['time_s_1']?.details?.title}
                  note={''}
                  formatDataFn={(data) => {
                    const format = (element) => ({
                      primary: new Date(element.datetime),
                      secondary: element.value_sum,
                      radius: 2
                    });
                    const sortBy = (a, b) => a.primary - b.primary;
                    const seriesNames = ['Vacantes nuevas', 'Media movil'];
                    return data.map((series, i) => (
                      {
                        label: seriesNames[i],
                        data: series.map(format).sort(sortBy)
                      }
                    ));
                  }}
                />
              </div>
            </div>
          </Section>
        </div>
        <div className="col-sm-12">
          <Section
            title={chartsDictionary['treemap_sectores']?.details?.title}
            delimitation={state?.label}
            id='keyed-treemap'
            information={chartsDictionary['treemap_sectores']?.details}
          >
            <KeyedTreemapSectionWithDataFetch
              queries={[
                {
                  url: '/group_filter',
                  query: {
                    table: 'enoe_scian',
                    schema: 'public',
                    select_filter: 'desc_scian_2,scian2,value',
                    cve_ent,
                    indicator: 'sh_empleo_ent_scian2',
                    orderdesc: 'value',
                    limit: 10
                  }
                }
              ]}
              formatDataFn={(data) => {
                const [elements] = data;
                return {
                  name: 'Data Elements',
                  children: elements.map((element) => {
                    return {
                      name: element.desc_scian_2,
                      value: element.value,
                      pct: element.value
                    }
                  })
                };
              }}
            />
          </Section>
        </div>
        <div className='col-sm-12'>
          <Section
            title={chartsDictionary['barplot_ocu_c/d']?.details?.title}
            delimitation={state?.label}
            id='ocupacion-crecimiento'
            information={chartsDictionary['barplot_ocu_c/d']?.details}
          >
            <div className="row">
              <div className="col-sm-12">
                <AbsoluteBarWithDataFetch
                  queries={[
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'desc_sinco_2dig,sinco2,value',
                        schema: 'public',
                        table: 'enoe_sinco',
                        cve_ent,
                        indicator: 'gyempleo',
                        orderdesc: 'value',
                        ignore_null: 'value',
                        limit: 5
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'desc_sinco_2dig,sinco2,value',
                        schema: 'public',
                        table: 'enoe_sinco',
                        cve_ent,
                        indicator: 'gyempleo',
                        orderasc: 'value',
                        ignore_null: 'value',
                        limit: 5
                      }
                    }
                  ]}
                  xTitle={chartsDictionary['barplot_ocu_c/d']?.details?.xlab}
                  yTitle={chartsDictionary['barplot_ocu_c/d']?.details?.ylab}
                  formatValue={(d) => format('.1%')(d)}
                  formatDataFn={(data) => {
                    const [top, bot] = data;
                    const elements = [...top, ...bot];
                    return elements.map((element) => {
                      return {
                        label: element.desc_sinco_2dig,
                        value: element.value ? element.value / 100 : 0
                      };
                    });
                  }}
                />
              </div>
            </div>
          </Section>
        </div>

        <div className='col-sm-12'>
          <Section
            title={chartsDictionary['share_vs_tasa']?.details?.title}
            delimitation={state?.label}
            id='scatter'
            information={chartsDictionary['share_vs_tasa']?.details}
          >
            <div className="row no-gutters">
              <div className="col-sm-12 col-md-9" style={{ background: '#fff' }}>
                <ScatterWithDataFetch
                  queries={[
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'sinco2,desc_sinco_2dig,p50_gtempleo,p50_sh,x,y',
                        schema: 'public',
                        table: 'share_vs_tasa',
                        cve_ent
                      },
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value',
                        schema: 'public',
                        table: 'enoe_sinco',
                        cve_ent,
                        // indicator: 'p50_empleo',
                        indicator: 'p50_shsincoemp_nac',
                        limit: 1
                        // group_filter?select_filter=value&schema=public&table=enoe_sinco&cve_ent=09&indicator=p50_empleo&limit=1
                      },
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value',
                        schema: 'public',
                        table: 'enoe_sinco',
                        cve_ent,
                        indicator: 'p50_sh_empleo_ent',
                        limit: 1
                        // group_filter?select_filter=value&schema=public&table=enoe_sinco&cve_ent=09&indicator=p50_sh_empleo_ent&limit=1
                      },
                    },
                  ]}
                  xTitle={chartsDictionary['share_vs_tasa']?.details.xlab}
                  yTitle={chartsDictionary['share_vs_tasa']?.details.ylab}
                  formatDataFn={(data) => {
                    const [elements, y, x] = data;
                    const xSeparator = x[0]?.value;
                    const ySeparator = 0;  // y[0].value;
                    function getQuadrant (x, y) {
                      if (x > xSeparator && y > ySeparator) {
                        return 1;
                      }
                      if (x > xSeparator && y < ySeparator) {
                        return 2;
                      }
                      if (x < xSeparator && y > ySeparator) {
                        return 4;
                      }
                      if (x < xSeparator && y < ySeparator) {
                        return 3;
                      }
                    }
                    const datapoints = elements.map((element) => {
                      return {
                        label: element.desc_sinco_2dig,
                        x: element.x,
                        y: element.y,
                        quadrant: getQuadrant(element.x, element.y)
                      };
                    });
                    return {
                      elements: datapoints,
                      xSeparator: xSeparator,
                      ySeparator: ySeparator,
                    }
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-3">
                <ScatterContext />
              </div>
            </div>
          </Section>
        </div>

        <div className="col-sm-12 col-md-12">
          <Section
            title={chartsDictionary['barplot_habilidades']?.details?.title}
            delimitation={state?.label}
            id='habilidades-demandadas'
            information={chartsDictionary['barplot_habilidades']?.details}
          >
            <div style={{ width: '100%' }}>
              <AbsoluteBarWithDataFetch
                hasNegative={false}
                margin={{
                  top: 10, right: 10, bottom: 30, left: 250
                }}
                queries={[
                  {
                    url: '/group_filter',
                    query: {
                      select_filter: 'habilidad,value',
                      schema: 'public',
                      table: 'skills_sin_sinco',
                      cve_ent,
                      limit: 10
                    }
                  }
                ]}
                formatValue={(d) => d.toLocaleString()}
                formatDataFn={(data) => {
                  const [elements] = data;
                  return elements.map((element) => {
                    return {
                      label: element.habilidad,
                      value: element.value
                    };
                  });
                }}
              />
            </div>
          </Section>
        </div>

        <div className="col-sm-12 col-md-12">
          <Section
            title={chartsDictionary['treemap_ocupacion']?.details?.title}
            delimitation={state?.label}
            id='sankey-ocupation'
            information={chartsDictionary['treemap_ocupacion']?.details}
          >
            <SankeyWithFetch
              height={600}
              isEmpty={(data) => {
                const [elements] = data;
                return elements.nodes.length === 0
                  || elements.edges.length === 0;
              }}
              queries={[
                {
                  url: 'network_group_filter',
                  query: {
                    schema: 'public',
                    table: 'skills_sinco_2',
                    categories: 'sincos,skills',
                    cve_ent
                  }
                }
              ]}

              formatValueFn={d => formatToPercentage(d / 100)}
              formatDataFn={(data) => {
                const [elements] = data;
                return toSankey(elements);
              }}
            />
          </Section>
        </div>

      </div>
    </div>
  )
}
