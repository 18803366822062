import React, {useEffect, useState} from 'react';
import {Section} from "../../components/Section";
import {ScatterContext} from "../../sections/ScatterContext";
import {scaleOrdinal} from "d3-scale";
import {arrColors} from "../../helpers/colors";
import {withDataFetch} from "../../components/withDataFetch";
import {TimeSeries} from "../../components/TimeSeries";
import Cloropeth from "../../components/Cloropeth";
import AbsoluteBar from "../../components/AbsoluteBar";
import {OverviewSection} from "../../components/sections";
import {LinearMapSection} from "../../sections/LinearMap";
import {KeyedTreemap} from "../../sections/KeyedTreemap";
import Scatter from "../../components/Scatter";
import {format} from "d3-format";
import axiosInstance from "../../api/axiosInstance";
import {useChartDictionary} from "../../helpers/useChartDictionary";
import {getTimeRange} from "../../helpers/date";

const TimeSerieWithDataFetch = withDataFetch(TimeSeries);
const CloropethWithDataFetch = withDataFetch(Cloropeth);
const AbsoluteBarWithDataFetch = withDataFetch(AbsoluteBar);

const OverviewSectionWithDataFetch = withDataFetch(OverviewSection)
const LinearMapSectionWithDataFetch = withDataFetch(LinearMapSection);
const KeyedTreemapSectionWithDataFetch = withDataFetch(KeyedTreemap);
const TestScatterWithDataFetch = withDataFetch(Scatter);

export const NationalOverview = (props) => {
  const chartsDictionary = useChartDictionary();
    const [startDate, endDate] = getTimeRange;
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <Section
            delimitation="Nacional"
            id='overview-nacional'
            title={chartsDictionary['time_s_1']?.details?.title}
            information={chartsDictionary['time_s_1']?.details}
          >
            <div className="row no-gutters">
              <div className="col-sm-12 col-md-4" style={{ background: '#f4f4f5'}}>
                <OverviewSectionWithDataFetch
                  queries={[
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent: '00',
                        indicator: 'vacantes'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent: '00',
                        indicator: 'empleo'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent: '00',
                        indicator: 'desempleo'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent: '00',
                        indicator: 'tasa_desempleo'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value,color',
                        schema: 'public',
                        table: 'enoe_main',
                        cve_ent: '00',
                        indicator: 'sh_informal'
                      }
                    },
                    {
                      url: '/data_to_filters',
                      query: {
                        categories: 'overview_nacional'
                      }
                    }
                  ]}
                  formatDataFn={(data) => {
                    const elements = data.reduce((acc, val) => ([...acc, val[0]]), []);
                    const [
                      vacant,
                      occupiedCount,
                      unoccupiedCount,
                      unoccupiedIndex,
                      informalityIndex,
                      information
                    ] = elements;
                    const dictionary = information.overview_nacional.reduce((acc, val) => {
                      acc[val.value] = val;
                      return acc;
                    }, {});
                    return {
                      vacantes: vacant,
                      empleo: occupiedCount,
                      desempleo: unoccupiedCount,
                      tasa_desempleo: unoccupiedIndex,
                      sh_informal: informalityIndex,
                      dictionary
                    };
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-8" style={{ background: '#fff' }}>
                <TimeSerieWithDataFetch
                  queries={[
                    {
                      url: '/group_filter',
                      query: {
                        table: 'job_vacancies_ds',
                        schema: 'public',
                        key_group: 'datetime',
                        operations: 'sum',
                          startdate: startDate,
                          enddate: endDate,
                        indicator: 'y'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        table: 'job_vacancies_ds',
                        schema: 'public',
                        key_group: 'datetime',
                        operations: 'sum',
                          startdate: startDate,
                          enddate: endDate,
                        indicator: 'y_rolling'
                      }
                    }
                  ]}
                  xAxisTitle={chartsDictionary['time_s_1']?.details?.xlab}
                  yAxisTitle={chartsDictionary['time_s_1']?.details?.ylab}
                  title={chartsDictionary['time_s_1']?.details?.title}
                  note={''}
                  formatDataFn={(data) => {
                    const format = (element) => ({
                      primary: new Date(element.datetime),
                      secondary: element.value_sum,
                      radius: 2
                    });
                    const sortBy = (a, b) => a.primary - b.primary;
                    const seriesNames = ['Vacantes nuevas', 'Media movil'];
                    return data.map((series, i) => (
                      {
                        label: seriesNames[i],
                        data: series.map(format).sort(sortBy)
                      }
                    ))
                  }}
                />
              </div>
            </div>
          </Section>
        </div>

        <div className="col-sm-12">
          <Section
            title={chartsDictionary['map_national']?.details?.title}
            delimitation="Nacional"
            id='overview-map'
            information={chartsDictionary['map_national']?.details}
          >
            <div className="row no-gutters">
              <LinearMapSectionWithDataFetch
                queries={[
                  {
                    url: '/group_filter',
                    query: {
                      select_filter: ['cve_ent', 'nom_ent', 'mes', 'vacantes_x'].join(','),
                      table: 'vacantes_nuevas_mes_ent',
                      schema: 'public'
                    }
                  }
                ]}
                formatDataFn={(data) => {
                  const [elements] = data;
                  return elements.map((element) => {
                    return {
                      id: element.cve_ent,
                      value: element.vacantes_x
                    }
                  });
                }}
              />

            </div>
          </Section>
        </div>

        <div className="col-sm-12">
          <Section
            title={chartsDictionary['treemap_sectores']?.details?.title}
            delimitation="Nacional"
            id='keyed-treemap'
            information={chartsDictionary['treemap_sectores']?.details}
          >
            <KeyedTreemapSectionWithDataFetch
              queries={[
                {
                  url: '/group_filter',
                  query: {
                    table: 'enoe_scian',
                    schema: 'public',
                    select_filter: ['desc_scian_2', 'scian2', 'value'].join(','),
                    cve_ent: '00',
                    indicator: 'shscian2emp_nac',
                    orderdesc: 'value',
                    limit: 10
                  }
                }
              ]}
              formatDataFn={(data) => {
                const [elements] = data;
                return {
                  name: 'Data Elements',
                  children: elements.map((element) => {
                    return {
                      name: element.desc_scian_2,
                      value: element.value,
                      pct: element.value
                    }
                  })
                };
              }}
            />
          </Section>
        </div>

        <div className="col-sm-12">
          <Section
            title={chartsDictionary['barplot_ocu_c/d']?.details?.title}
            information={chartsDictionary['barplot_ocu_c/d']?.details}
            id='ocupacion-crecimiento'
            delimitation="Nacional"
          >
            <div className="row">
              <div className="col-sm-12">
                <AbsoluteBarWithDataFetch
                  queries={[
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'desc_sinco_2dig,sinco2,value',
                        schema: 'public',
                        table: 'enoe_sinco',
                        cve_ent: '00',
                        indicator: 'gyempleo',
                        orderdesc: 'value',
                        limit: 5,
                        ignore_null: 'value'
                      }
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'desc_sinco_2dig,sinco2,value',
                        schema: 'public',
                        table: 'enoe_sinco',
                        cve_ent: '00',
                        indicator: 'gyempleo',
                        orderasc: 'value',
                        limit: 5,
                        ignore_null: 'value'
                      }
                    }
                  ]}
                  xTitle={chartsDictionary['barplot_ocu_c/d']?.details?.xlab}
                  yTitle={chartsDictionary['barplot_ocu_c/d']?.details?.ylab}
                  formatValue={(d) => format('.1%')(d)}
                  formatDataFn={(data) => {
                    const [top, bot] = data;
                    const elements = [...top, ...bot];
                    return elements.map((element) => {
                      return {
                        label: element.desc_sinco_2dig,
                        value: element.value ? element.value / 100 : 0
                      };
                    });
                  }}
                />
              </div>
            </div>
          </Section>
        </div>


        <div className="col-sm-12">
          <Section
              id='scatter'
            title={chartsDictionary['share_vs_tasa']?.details?.title}
            information={chartsDictionary['share_vs_tasa']?.details}
              delimitation="Nacional"
          >

            <div className="row no-gutters">
              <div className="col-sm-12 col-md-9" style={{ background: '#fff' }}>
                <TestScatterWithDataFetch
                  queries={[
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'sinco2,desc_sinco_2dig,p50_gtempleo,p50_sh,x,y',
                        schema: 'public',
                        table: 'share_vs_tasa',
                        cve_ent: '00'
                      },
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value',
                        schema: 'public',
                        table: 'enoe_sinco',
                        cve_ent: '00',
                        indicator: 'p50_shsincoemp_nac',
                        limit: 1
                      },
                    },
                    {
                      url: '/group_filter',
                      query: {
                        select_filter: 'value',
                        schema: 'public',
                        table: 'enoe_sinco',
                        cve_ent: '00',
                        indicator: 'p50_gtempleo',
                        limit: 1
                      },
                    }
                  ]}
                  xTitle={chartsDictionary['share_vs_tasa']?.details.xlab}
                  yTitle={chartsDictionary['share_vs_tasa']?.details.ylab}
                  formatDataFn={(data) => {
                    const [elements, y, x] = data;
                    const xSeparator = x[0].value;
                    const ySeparator = y[0].value;
                    function getQuadrant (x, y) {
                      if (x > xSeparator && y > ySeparator) {
                        return 1;
                      }
                      if (x > xSeparator && y < ySeparator) {
                        return 2;
                      }
                      if (x < xSeparator && y > ySeparator) {
                        return 4;
                      }
                      if (x < xSeparator && y < ySeparator) {
                        return 3;
                      }
                    }
                    const datapoints = elements.map((element) => {
                      return {
                        label: element.desc_sinco_2dig,
                        x: element.x,
                        y: element.y,
                        quadrant: getQuadrant(element.x, element.y)
                      };
                    });
                    return {
                      elements: datapoints,
                      xSeparator: x[0].value,
                      ySeparator: y[0].value,
                    }
              }}
            />
              </div>
              <div className="col-sm-12 col-md-3">
                <ScatterContext />
              </div>
            </div>
          </Section>
        </div>


        <div className="col-sm-12 col-md-12">
          <Section
            title={chartsDictionary['barplot_habilidades']?.details?.title}
            delimitation="Nacional"
            id='habilidades-demandadas'
            information={chartsDictionary['barplot_habilidades']?.details}
          >
            <div style={{ width: '100%' }}>
              <AbsoluteBarWithDataFetch
                hasNegative={false}
                queries={[
                  {
                    url: '/group_filter',
                    query: {
                      select_filter: 'habilidad,value',
                      schema: 'public',
                      table: 'skills_sin_sinco',
                      cve_ent: '00',
                      limit: 10
                    }
                  }
                ]}
                formatValue={(d) => d.toLocaleString()}
                formatDataFn={(data) => {
                  const [elements] = data;
                  return elements.map((element) => {
                    return {
                      label: element.habilidad,
                      value: element.value
                    };
                  });
                }}
              />
            </div>
          </Section>
        </div>

        <div className="col-sm-12 col-md-12">
          <Section
            title={chartsDictionary['map_habilidades']?.details?.title}
            delimitation="Nacional"
            id='habilidades-map'
            information={chartsDictionary['map_habilidades']?.details}
          >
            <div style={{ background: '#fff' }}>

              <CloropethWithDataFetch
                height={600}
                queries={[
                  {
                    url: '/group_filter',
                    query: {
                      select_filter: 'cve_ent,habilidad',
                      schema: 'public',
                      table: 'skill_top_ent'
                    }
                  }
                ]}
                formatDataFn={(data) => {
                  const [elements] = data;
                  return elements.map((element) => {
                    return {
                      id: element.cve_ent,
                      value: element.habilidad
                    }
                  });
                }}
                scale={(data) => {
                  return scaleOrdinal()
                    .unknown('#fff')
                    .domain(data.map(d => d.value))
                    .range(arrColors)
                }}
              />
            </div>
          </Section>
        </div>

      </div>
    </div>
  )
}
