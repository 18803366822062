import React from 'react';
import { AxisOptions, Chart } from "react-charts";
import ErrorBoundary from "./ErrorBoundary";

export class ScatterBubble extends React.Component {
  render () {
    const primaryAxis = { getValue: (d) => d.primary };

    const secondaryAxes = [
      {
        getValue: (d) => d.secondary,
        elementType: "bubble",
      }
    ];

    const { data } = this.props;

    return (
      <ErrorBoundary>

      <div style={{ height: 500 }}>
        <Chart
          options={{
            data,
            primaryAxis,
            secondaryAxes,
            interactionMode: "closest",
            getDatumStyle: (datum) =>
              ({
                circle: { r: datum.originalDatum.radius },
              })
          }}
        />
      </div>
      </ErrorBoundary>
    );
  }
}