import React from "react";
import { National } from "./views/national/National";
import { State } from "./views/state/State";
import { City } from "./views/city/City";
import { Routes, Route, Link } from "react-router-dom";
import { Layout } from "./components/Layout";
import './App.css'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/app" element={<Layout />}>
          <Route index element={<National />} />
          <Route path="/app/estatal" element={<State />} />
          <Route path="/app/ciudad-autonombrada" element={<City />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
