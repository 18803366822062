import React from 'react'
import { Section } from "../../components/Section";
import { EmploymentTreemap } from "../../sections/city/detail/EmploymentTreemap";
import Sankey from "../../components/Sankey";
import {withDataFetch} from "../../components/withDataFetch";
import {BoxPlotSection} from "../../components/BoxPlotSection";
import {EducationLevel} from "../../sections/city/detail/EducationLevel";
import {Age} from "../../sections/city/detail/Age";
import {Gender} from "../../sections/city/detail/Gender";
import {WorkingDay} from "../../sections/city/detail/WorkingDay";
import {Salary} from "../../sections/city/detail/Salary";
import {toSankey} from "../../helpers/dataTransformations";
import {useChartDictionary} from "../../helpers/useChartDictionary";

const SankeyWithFetch = withDataFetch(Sankey);

import salary_icon from '../../images/job_salary.png';
import education_icon from '../../images/job_educativo.png';
import age_icon from '../../images/job_edad.png';
import jornada_icon from '../../images/job_jornada.png';
import {formatToPercentage} from "../../helpers/formatters";

export const CityDetail = ({ cve_cda = '03', city = {} }) => {
  const chartsDictionary = useChartDictionary();
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <Section
            title={chartsDictionary['treemap_ocupacion']?.details?.title}
            delimitation={city?.label}
            id='ocupation-treemap'
            information={chartsDictionary['treemap_ocupacion']?.details}
          >
            <EmploymentTreemap cve_cda={cve_cda} />
          </Section>
        </div>

        <div className="col-sm-12 col-md-6">
          <Section
            title={chartsDictionary['sankey_ocupaciones_habilidades_sinco2']?.details?.title}
            id='ocupation-sankey'
            delimitation={city?.label}
            information={chartsDictionary['sankey_ocupaciones_habilidades_sinco2']?.details}
          >
            <SankeyWithFetch
              height={500}
              queries={[
                {
                  url: 'network_group_filter',
                  query: {
                    schema: 'public',
                    table: 'skills_sinco_1',
                    categories: 'sincos,skills',
                    cve_cda
                  }
                }
              ]}
              formatDataFn={(data) => {
                const [elements] = data;
                return toSankey(elements);
              }}
              formatValueFn={d => formatToPercentage(d / 100)}
            />
          </Section>
        </div>

        <div className="col-sm-12 col-md-12">
          <Section
            title="Ocupaciones con mayor demanda"
            delimitation={city?.label}
            information={{
                ...chartsDictionary['barplot_salario']?.details,
                source: null,
                caption: null
            }}
          >
            <BoxPlotSection
              title={chartsDictionary['barplot_salario']?.details?.title}
              id='salary'
              meta={city?.label}
              icon={<img alt='Salary Icon' src={salary_icon} />}
              information={chartsDictionary['barplot_salario']?.details}
            >
              <Salary cve_cda={cve_cda} />
            </BoxPlotSection>

            <BoxPlotSection
              title={chartsDictionary['barplot_educacion']?.details?.title}
              id='education'
              meta={city?.label}
              icon={<img alt='Education Icon' src={education_icon} />}
              information={chartsDictionary['barplot_educacion']?.details}
            >
              <EducationLevel cve_cda={cve_cda} />
            </BoxPlotSection>

            <BoxPlotSection
              title={chartsDictionary['barplot_edad']?.details?.title}
              id='age'
              meta={city?.label}
              icon={<img alt='Age Icon' src={age_icon} />}
              information={chartsDictionary['barplot_edad']?.details}
            >
              <Age cve_cda={cve_cda}/>
            </BoxPlotSection>

            <BoxPlotSection
              title={chartsDictionary['barplot_genero']?.details?.title}
              id='gender'
              meta={city?.label}
              information={chartsDictionary['barplot_genero']?.details}
            >
              <Gender cve_cda={cve_cda} />
            </BoxPlotSection>

            <BoxPlotSection
              title={chartsDictionary['Jornada']?.details?.title}
              id='jornada'
              meta={city?.label}
              icon={<img alt='Jornada Icon' src={jornada_icon} />}
              information={chartsDictionary['Jornada']?.details}
            >
              <WorkingDay cve_cda={cve_cda} />
            </BoxPlotSection>

          </Section>
        </div>


      </div>
    </div>
  );
}