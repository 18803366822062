import React from "react";
import Treemap from "../../components/Treemap";

import { withDataFetch } from "../../components/withDataFetch";
import {Section} from "../../components/Section";
import Sankey from "../../components/Sankey";
import { EducationLevel } from "../../sections/occupations/EducationLevel";
import { WorkingDay } from "../../sections/occupations/WorkingDay";
import { Gender } from "../../sections/occupations/Gender";
import { Age } from "../../sections/occupations/Age";
import { EmploymentTreemap } from "../../sections/national/detail/EmploymentTreemap";
import { Salary } from "../../sections/national/detail/Salary";
import { BoxPlotSection } from "../../components/BoxPlotSection";
import {toSankey} from "../../helpers/dataTransformations";
import {useChartDictionary} from "../../helpers/useChartDictionary";

const SankeyWithFetch = withDataFetch(Sankey);

import salary_icon from '../../images/job_salary.png';
import education_icon from '../../images/job_educativo.png';
import age_icon from '../../images/job_edad.png';
import jornada_icon from '../../images/job_jornada.png';
import {formatToPercentage} from "../../helpers/formatters";

export const NationalDetail = (props) => {
    const chartsDictionary = useChartDictionary();
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-6">
                    <Section
                      title={chartsDictionary['treemap_ocupacion']?.details?.title}
                      id='ocupation-treemap'
                      delimitation="Nacional"
                      information={chartsDictionary['treemap_ocupacion']?.details}
                    >
                        <EmploymentTreemap />
                    </Section>
                </div>

                <div className="col-sm-6">
                    <Section
                      title="Ocupaciones con mayor empleo y sus habilidades requeridas"
                      id='ocupation-sankey'
                      delimitation="Nacional"
                      information={chartsDictionary['sankey_ocupaciones_habilidades_sinco2']?.details}
                    >
                        <SankeyWithFetch
                            height={500}
                            queries={[
                                {
                                    url: 'network_group_filter',
                                    query: {
                                        schema: 'public',
                                        table: 'skills_sinco_1',
                                        categories: 'sincos,skills',
                                        cve_ent: '00'
                                    }
                                }
                            ]}
                            formatDataFn={(data) => {
                                const [elements] = data;
                                return toSankey(elements);
                            }}
                            formatValueFn={d => formatToPercentage(d / 100)}
                        />
                    </Section>
                </div>

                <div className="col-sm-12">
                    <Section
                      title="Ocupaciones con mayor demanda"
                      delimitation="Nacional"
                      id='nacional-plots'
                      information={{
                          ...chartsDictionary['barplot_salario']?.details,
                          source: null,
                          caption: null
                      }}
                    >
                        <BoxPlotSection
                          title={chartsDictionary['barplot_salario']?.details?.title}
                          meta="Nacional"
                          id='salary'
                          icon={<img alt='Salary Icon' src={salary_icon} />}
                          information={chartsDictionary['barplot_salario']?.details}
                        >
                            <Salary />
                        </BoxPlotSection>

                        <BoxPlotSection
                          title={chartsDictionary['barplot_educacion']?.details?.title}
                          meta="Nacional"
                          id='education'
                          icon={<img alt='Education Icon' src={education_icon} />}
                          information={chartsDictionary['barplot_educacion']?.details}
                        >
                            <EducationLevel />
                        </BoxPlotSection>
                        <BoxPlotSection
                          title={chartsDictionary['barplot_edad']?.details?.title}
                          meta="Nacional"
                          id='age'
                          icon={<img alt='Age Icon' src={age_icon} />}
                          information={chartsDictionary['barplot_edad']?.details}
                        >
                            <Age />
                        </BoxPlotSection>
                        <BoxPlotSection
                          title={chartsDictionary['barplot_genero']?.details?.title}
                          meta="Nacional"
                          id='gender'
                          information={chartsDictionary['barplot_genero']?.details}
                        >
                            <Gender />
                        </BoxPlotSection>
                        <BoxPlotSection
                          title={chartsDictionary['Jornada']?.details?.title}
                          meta="Nacional"
                          id='jornada'
                          icon={<img alt='Jornada Icon' src={jornada_icon} />}
                          information={chartsDictionary['Jornada']?.details}
                        >
                            <WorkingDay />
                        </BoxPlotSection>
                    </Section>
                </div>

            </div>
        </div>
    )
}