import React from 'react';
import { nanoid } from 'nanoid';
import { treemap, treemapSquarify, hierarchy } from "d3-hierarchy";
import { withSize } from 'react-sizeme'

export class Treemap extends React.Component {
  constructor(props) {
    super(props);
  }

  transformData = () => {
    const { size: { width }, height, data, valueFn } = this.props;

    return treemap()
      .tile(treemapSquarify)
      .size([width, height])
      .padding(1)
      .round(true)
      (
        hierarchy(data)
          .sum(valueFn)
          .sort((a, b) => b.value - a.value)
      )
  }

  getTileFill = (d, index) => {
    while (d.depth > 1) {
      d = d.parent;
    }
    return this.props.colorScale(d.data.name);
  }

  splitTileTitle = (tile) => {
    let title = tile.data.name;
    const { formatValueFn } = this.props;
    const value = tile.data.value;
    if (!title) title = 'No Title Provided';
    const formattedValue = formatValueFn(value) || '-';
    return title
      .split(/(?=[A-Z][a-z])|\s+/g)
      .concat(formattedValue);
  }

  renderTitleSpan = (text, i) => {
    i++; // hack
    return (
      <tspan
        key={i}
        x={3}
        y={`${i * 1}em`}
        fill='#fff'
        fontWeight={'bold'}
        fontSize={12}
      >
        {text}
      </tspan>
    )
  }

  renderTile = (tile, index) => {
    const leafUID = nanoid(10);
    const clipUID = nanoid(10);
    const titleElements = this.splitTileTitle(tile);
    const { formatValueFn } = this.props;
    return (
      <g transform={`translate(${tile.x0}, ${tile.y0})`} key={index}>
        <rect
          id={leafUID}
          onMouseEnter={(e) => console.log(tile)}
          fill={this.getTileFill(tile, index)}
          stroke={this.getTileFill(tile, index)}
          fillOpacity={1}
          width={tile.x1 - tile.x0}
          height={tile.y1 - tile.y0}
        >
          <title>{tile.data.name} - {formatValueFn(tile.data.value)}</title>
        </rect>
        <clipPath id={clipUID}>
          <use xlinkHref={`#${leafUID}`} />
        </clipPath>
        <text clipPath={`url(#${clipUID})`} x={0} y={0}>
          {titleElements.map(this.renderTitleSpan)}
        </text>
      </g>
    );
  }

  render () {
    const { height, size: { width } } = this.props;
    const data = this.transformData();
    const leaves = data.leaves();
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <svg width={width} height={height} >
          {leaves.map(this.renderTile)}
        </svg>
      </div>
    );
  }
}

Treemap.defaultProps = {
  data: {
    name: '',
    children: []
  },
  colorScale: (d) => '#222',
  size: {
    width: 0,
    height: 0
  },
  height: 500,
  width: 1000,
  valueFn: (d) => d.value,
  sortFn: (a, b) => b.value - a.value,
  formatValueFn: (d) => d
}

export default withSize()(Treemap)
